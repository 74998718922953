import request from '@/utils/request'

//Login
export function loadPaginatedAutoPMA(page) {
  return request({ url: 'autopmaproductlist', method: 'post', data: {'page': page} })
} 

export function stopAutoPMA(data) {
  return request({ url: 'removeautopma', method: 'post', data: data})
} 


export function saveAutoPMA(data) {
  return request({ url: 'saveautopma', method: 'post', data: data})
} 

