
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {loadPaginatedAutoPMA, stopAutoPMA, saveAutoPMA}  from "@/api/autopma";
import { number, string } from "yup/lib/locale";


export default defineComponent({
  name: "biboactivations",
  components: {
  },
  data() {
    return { 
      list : [],
      autopma_details : [],
      divAmount_show : false,
      pma_amount : 0,
      currency : "",
      btnsearch_show : false,
      btnsave_show : false,
      btncancel_show : false,
      selected_prodcode : "",
      isprocessing : {
        type: string,
        default : []
      },
      selectBtn_arr : {
        type: string,
        default : []
      },
      saveBtn_arr : {
        type: string,
        default : []
      },
      cancelBtn_arr : {
        type: string,
        default : []
      },
      stopBtn_arr : {
        type: string,
        default : []
      },
      loading : false,
      prevIndexHolder : -1,
      autopma_level_dropdown_disabled_arr : {
        type: string,
        default : []
      },
      autopma_level_selected : {
        type: number,
        default : []
      },
      selectBtn_disabled_arr : {
        type: string,
        default : []
      },
      pospv_arr : {
        type: string,
        default : []
      },
      distPrice_arr : {
        type: string,
        default : []
      },
      warningAmount : 0,
      branch_arr : {
        type: string,
        default : []
      },
      prodreleasebranchcode_selected : {
        type: number,
        default : []
      },
      autopma_prodreleasebranchcode_dropdown_disabled_arr : {
        type: string,
        default : []
      },

    }
  },
  mounted(){
      this.getPaginatedAutoPMA();
  },
  methods : {
    async setDefaultValues(listSize:number){
      let ctr = 0;
      for(ctr = 0; ctr < listSize; ctr++){
          this.isprocessing[ctr] = "0";
          this.saveBtn_arr[ctr] = "0";
          this.cancelBtn_arr[ctr] = "0";
          this.stopBtn_arr[ctr] = "0";
          this.autopma_level_dropdown_disabled_arr[ctr] = "1";
          this.autopma_prodreleasebranchcode_dropdown_disabled_arr[ctr] = "1";
      }
    },
    async getPaginatedAutoPMA(){ 
      this.loading = true;
      const response = await loadPaginatedAutoPMA(1);
      this.loading = false;
      this.list = response.data.autoPMAProductList;
      this.currency = response.data.currency;
      this.btnsearch_show = true;
      let listSize = this.list.length;
      this.setDefaultValues(listSize);


      if(response.data.autoPMADetails != undefined && response.data.autoPMADetails != null){
        this.autopma_details = response.data.autoPMADetails;
        for(var ctr = 0; ctr < this.list.length; ctr++){
          var current_item = JSON.parse(JSON.stringify(this.list[ctr]));
           if(current_item.prodcode == response.data.autoPMADetails.prodcode){
            this.branch_arr[ctr] = response.data.branches;
            this.selected_prodcode = current_item.prodcode;
            this.autopma_level_selected[ctr] = response.data.autoPMADetails.pma_level;
            this.selectBtn_arr[ctr] = "0";
            this.warningAmount = parseFloat(this.autopma_level_selected[ctr]) * parseFloat(current_item.dist_price);
            this.prodreleasebranchcode_selected[ctr] = response.data.autoPMADetails.prodreleasebranchcode;

        console.log("VAN " + response.data.autoPMADetails.prodreleasebranchcode);

           } else {
            this.selectBtn_arr[ctr] = "1";
            this.autopma_level_selected[ctr] = 1;
            this.prodreleasebranchcode_selected[ctr] = "XXX";
           }
            this.selectBtn_disabled_arr[ctr] = "1";
            this.pospv_arr[ctr] = current_item.pospv;
            this.distPrice_arr[ctr] = current_item.dist_price;
            
        }

        this.divAmount_show = true;
      } else {
        this.divAmount_show = false;
        for(var ctr = 0; ctr < this.list.length; ctr++){
          var current_item = JSON.parse(JSON.stringify(this.list[ctr]));
            this.selectBtn_arr[ctr] = "1";
            this.autopma_level_selected[ctr] = 1;
            this.prodreleasebranchcode_selected[ctr] = "XXX";
            this.pospv_arr[ctr] = current_item.pospv;
            this.distPrice_arr[ctr] = current_item.dist_price;
            this.branch_arr[ctr] = response.data.branches;
        }
      }
      console.log(response.data.autoPMADetails);
    },
    async highlightAutoPMASetting(autoPMADetails){
      console.log(autoPMADetails.prodcode);
      console.log(autoPMADetails.usid);
      console.log(autoPMADetails.pma_level);
    },
    async stopAutoPMANow(index){
      this.isprocessing[index] = "1";
      var param = {
        "X" : ""
      };
      const response = await stopAutoPMA(param);
      this.isprocessing[index] = "0";
      if (response.data.result == "SUCCESS") {
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        }); 
        window.location.reload();
      } else if(response.data.result == "FAILED"){
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        }); 
      }

      for(var ctr = 0; ctr < this.list.length; ctr++){
        this.selectBtn_disabled_arr[ctr] = "1";
      }

    },
    async saveAutoPMANow(prodcode, index){
        
        console.log(prodcode);
        console.log(index);
        console.log(this.autopma_level_selected[index]);
        console.log(this.prodreleasebranchcode_selected[index]);


      if(this.prodreleasebranchcode_selected[index] == "XXX"){
        Swal.fire({
            title: "",
            text: "Please select a branch.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
      } else {
        this.isprocessing[index] = "1";
          var params = {
          "prodcode" : prodcode,
          "level" : this.autopma_level_selected[index],
          "branch" : this.prodreleasebranchcode_selected[index]
          }

          const response = await saveAutoPMA(params);
          this.isprocessing[index] = "0";
          if (response.data.result == "SUCCESS") {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          }); 
          window.location.reload();
        } else if(response.data.result == "FAILED"){
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          }); 
        }

        for(var ctr = 0; ctr < this.list.length; ctr++){
          this.selectBtn_disabled_arr[ctr] = "1";
        }
      }


        
    },
    async computePointsAndPrice(index){
      /*
      var lvl = $('#autopma_level_'+prodcode).val();
      var retVal = points * lvl;
      $('#tdPoints_' + prodcode).text(retVal);
      
      var lvl = $('#autopma_level_'+prodcode).val();
      var retVal = price * lvl;
      $('#tdPrice_' + prodcode).text(numberWithCommas(retVal.toFixed(2)));
      $('#pma_amount').text("${currency} "+numberWithCommas(retVal.toFixed(2)));
      */

     
    },
    async resetTablePoints(prevIndex){
      /*
      var pts = 0.00;
      var price = 0.00;
      var lvl = $('#autopma_level_' + prodcode).val();
      var tdPoints = $('#tdPoints_' + prodcode).text();
      var tdPrice = $('#tdPrice_' + prodcode).text();
      tdPrice = tdPrice.replace(",","");
      pts = tdPoints / lvl;
      price = tdPrice / lvl;
      $('#tdPoints_' + prodcode).text(pts);
      $('#tdPrice_' + prodcode).text(numberWithCommas(price.toFixed(2)));
      $('#autopma_level_'+prodcode).val(1);
  
      */

      if(prevIndex >= 0){
        this.selectBtn_arr[prevIndex] = "1";
        this.saveBtn_arr[prevIndex] = "0";
        this.cancelBtn_arr[prevIndex] = "0";
        this.stopBtn_arr[prevIndex] = "0";
        this.autopma_level_selected[prevIndex] = 1;
        this.autopma_level_dropdown_disabled_arr[prevIndex] = "1";
        this.autopma_prodreleasebranchcode_dropdown_disabled_arr[prevIndex] = '1';
      }




    },
    async selectProduct(prodcode, index){
      this.setDefaultValues(this.list.length);
      this.resetTablePoints(this.prevIndexHolder);
      this.prevIndexHolder = index;
      /*
      prevProdCode = prodcode;
      $('.auto-pma-prod-row').css('background-color','#fff');
      $('.drp-level').prop("disabled", true);
      $('#row_' + prodcode).css('background-color','#cfc');
      $('#autopma_level_'+prodcode).prop("disabled", false);
      
*/
      this.autopma_level_dropdown_disabled_arr[index] = "0";
      this.selectBtn_arr[index] = "0";
      this.saveBtn_arr[index] = "1";
      this.cancelBtn_arr[index] = "1";
      this.stopBtn_arr[index] = "0";
      this.autopma_prodreleasebranchcode_dropdown_disabled_arr[index] = '0';
      
    }
    
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("autoPMATab") || "0");
      setCurrentPageTitle("Auto PMA");

    });
    
  }
});
